import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"
import SundaySafari from "../img/sundaysafari.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["#29abe2", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "SundaySafari",
    lastName: "",
    initials: "SundaySafari", // the example uses first and last, but feel free to use three or more if you like.
    position: "Refreshing treats on the go, where will you find us next?",
    selfPortrait: SundaySafari, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        


    ],
    socials: [





    ],
    bio: "Clover Lab is a dynamic technology company based out of Vancouver Canada. Specializing in the optimization of technology operations, Clover Lab has a targeted focus on serving small and medium-sized businesses (SMBs). Our team of highly skilled professionals brings a wealth of experience and expertise to our clients, delivering tailored solutions that enhance productivity and drive business growth.",
    bioSkills: "Our team's skills extend to effective business analysis, project management, and cybersecurity measures, ensuring streamlined, secure, and efficient operations for (SMBs). Furthermore, we are proficient in leveraging M365 and gSuite business applications, providing businesses with seamless communication, collaboration, and productivity tools.",
    AISkills: "Clover Lab's AI proficiency is beyond compare. We work with AI technologies to facilitate intelligent integration, enhancing efficiency and providing a competitive edge for our clients.",

    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Project 1",
            live: "https://paytonpierce.dev", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://github.com/paytonjewell", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        },
        {
            title: "Project 2",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock2
        },
        {
            title: "Project 3",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock3
        },
        {
            title: "Project 4",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock4
        },
        {
            title: "Project 5",
            live: "https://paytonpierce.dev",
            source: "https://github.com/paytonjewell",
            image: mock5
        }
    ]
}