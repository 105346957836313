import React from 'react';
import Style from './Home.module.scss';
import me from '../../img/self.png';
import sundaysafari from '../../img/sundaysafari.png'
import classNames from 'classnames';
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import {Box, TextField, Button} from "@mui/material";
import {info} from "../../info/Info";
import emailjs from 'emailjs-com';

export default function Home() {
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);

  const handleInputChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(form.email)) {
      setEmailError('Please enter a valid email');
      return;
    }
  
    emailjs.send(
      'service_hs8plil', 
      'template_dzhv9mk', 
      form, 
      'vhdvTpQCHDanns4mb' // replace 'user_id' with your actual user_id
    )
    .then((result) => {
      console.log(result.text);
      setStatus("Email sent successfully, you will should hear back from us shortly");
    }, (error) => {
      console.log(error.text);
      setStatus("Error occurred while sending Email");
    });
  
    setForm({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <React.Fragment>
      <Box 
        component="section" 
        style={{
          position: 'relative',
          width: '100%',
          height: '60vh', // Adjust the height as needed
          overflow: 'hidden',
        }}
      >
        <Box 
          component={'img'} 
          src={sundaysafari} 
          alt={'image of company'}
          sx={{
            width: '100%',
            height: '100%',
            padding: '20px',
            boxSizing: 'border-box',
            '@media (min-width:600px)': { 
              objectFit: 'cover',
            },
            '@media (max-width:599px)': { 
              objectFit: 'contain',
            },
          }}
        />
      </Box>
      <Box 
        component={'main'} 
        display={'flex'} 
        flexDirection={{xs: 'column', md: 'row'}} 
        alignItems={{xs: 'center', md: 'flex-start'}}
        justifyContent={'center'} 
        minHeight={'calc(40vh - 175px)'} // Adjust this value as needed 
        padding={'5px'}
      >
        <Box padding={'5px'}>
            <h1>Coming Soon! <span style={{background: info.gradient, WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}></span><span className={Style.hand}>🤚</span>
            </h1>
            <h2> {info.position}</h2>
            <Box padding={'5px'}>
               <h3 style={{ fontSize: "1.5em", color: "#333", marginTop:"1rem" }}>
               </h3>
            </Box>

            <Box padding={'5px'} component={'ul'} p={'0.8rem'}>
               {info.miniBio.map((bio, index) => (
                  <EmojiBullet key={index} emoji={bio.emoji} text={bio.text}/>
               ))}
            </Box>
            <Box padding={'5px'}>
               <h3 style={{ fontSize: "1.5em", color: "#333", marginTop:"1rem" }}>
               Stay in touch!
               </h3>
            </Box>
            {/* Contact Form */}
            {status === null && (
              <Box  padding={'5px'} component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField 
                  name="name"
                  label="Name" 
                  variant="outlined"
                  value={form.name}
                  onChange={handleInputChange} 
                  fullWidth
                  required
                  margin={'normal'}
                />
                <TextField 
                  name="email"
                  label="Email" 
                  variant="outlined"
                  value={form.email}
                  onChange={handleInputChange} 
                  fullWidth
                  required
                  margin={'normal'}
                />
<Button variant="contained" type="submit" style={{marginTop: '20px', width: '100%', backgroundColor: '#29abe2'}}>
  Join The Newsletter
</Button>

              </Box>
            )}
            {/* Status message */}
            {status && (
              <Box display="flex" justifyContent="center" marginBottom={'2rem'} marginTop={'2rem'}>
  <p style={{ fontSize: '1.5em', color: 'grey' }}>{status}</p>
</Box>

            )}

            <Box display={'flex'} gap={'1.5rem'} justifyContent={'center'} fontSize={{xs: '2rem', md: '2.5rem'}}>
               {info.socials.map((social, index) => (
                  <SocialIcon key={index} link={social.link} icon={social.icon} label={social.label} />
               ))}
            </Box>
         </Box>
      </Box>
    </React.Fragment>
   )
}
