import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import {Box} from "@mui/material";
import {info} from "../../info/Info";


export default function About() {
    const firstName = info.firstName.toLowerCase()

    function aboutMeText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cat about{firstName}</p>
            <p><span style={{color: info.baseColor}}>about{firstName} <span className={Style.green}>(main)</span> $ </span>{info.bio}</p>
        </>;
    }

    function skillsText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cat expertise</p>
            <p><span style={{color: info.baseColor}}>Our expertise <span className={Style.green}>(main)</span> $ </span>{info.bioSkills}</p>
        </>;
    }

    function AIskillsText() {
        return <>
            <p><span style={{color: info.baseColor}}>{firstName}{info.lastName.toLowerCase()} $</span> cat AI </p>
            <p><span style={{color: info.baseColor}}>AI <span className={Style.green}>(main)</span> $ </span>{info.AISkills}</p>
        </>;
    }




    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal text={aboutMeText()}/>
            <Terminal text={skillsText()}/>
            <Terminal text={AIskillsText()}/>
        </Box>
    )
}